import { PROFILE_NEW_USER } from '@/constants/dialogs'

export const navigationListStep = [
  {
    id: 1,
    title: 'Квоты',
    route: '/',
    name: 'Home',
    icon: 'IconQuotas',
  },
  {
    id: 3,
    title: 'Управление',
    route: '/control',
    name: 'Control-terminal',
    icon: 'TruckIcon',
    children: [
      {
        id: 31,
        title: 'Tерминал',
        name: 'Control-terminal',
        unload: [
          'cerkizovo-masla-g-elec',
          'cerkizovo-svinovodstvo-rp-latnaa',
          'step',
        ],
      },
      {
        id: 32,
        title: 'Культуры',
        name: 'Control-Culture',
        unload: [
          'cerkizovo-masla-g-elec',
          'cerkizovo-svinovodstvo-rp-latnaa',
          'step',
        ],
      },
      {
        id: 33,
        title: 'Экспортеры',
        name: 'Control-Exporters',
        unload: [
          'cerkizovo-masla-g-elec',
          'cerkizovo-svinovodstvo-rp-latnaa',
          'step',
        ],
      },
      {
        id: 34,
        title: 'Черный список',
        name: 'Control-Blacklist',
        unload: [
          'cerkizovo-masla-g-elec',
          'cerkizovo-svinovodstvo-rp-latnaa',
          'step',
        ],
      },
      {
        id: 35,
        title: 'Транспорт терминала',
        name: 'Control-Whitelist',
        unload: [
          'cerkizovo-masla-g-elec',
          'cerkizovo-svinovodstvo-rp-latnaa',
          'step',
        ],
      },
    ],
  },
  {
    id: 15022022,
    title: 'Выезд',
    route: '/admin-supervisor',
    name: 'AdminSupervisor',
    icon: 'IconEntry',
  },
  {
    id: 6,
    title: 'Настройки',
    route: '/settings',
    name: 'Settings',
    icon: 'WrenchIcon',
  },
  {
    id: 4,
    title: 'Помощь',
    route: '/about',
    name: 'About',
    icon: 'IconHelp',
  },
  {
    id: 5,
    title: 'Профиль',
    route: '/profile',
    name: 'Profile-settings',
    icon: 'IconUserRegistration',
    children: [
      {
        id: 51,
        title: 'Мой профиль',
        name: 'Profile-settings',
      },
      {
        id: 52,
        title: 'Профили сотрудников',
        name: 'Profile-table',
      },
      {
        id: 53,
        title: 'Добавить нового сотрудника',
        name: '',
        dialog: PROFILE_NEW_USER,
      },
    ],
  },
]
export const navigationList = [
  {
    id: 1,
    title: 'Квоты',
    route: '/',
    name: 'Home',
    icon: 'IconQuotas',
  },
  {
    id: 3,
    title: 'Управление',
    route: '/control',
    name: 'Control-terminal',
    icon: 'TruckIcon',
    children: [
      {
        id: 31,
        title: 'Tерминал',
        name: 'Control-terminal',
        unload: [
          'cerkizovo-masla-g-elec',
          'cerkizovo-svinovodstvo-rp-latnaa',
          'step',
        ],
      },
      {
        id: 32,
        title: 'Культуры',
        name: 'Control-Culture',
        unload: [
          'cerkizovo-masla-g-elec',
          'cerkizovo-svinovodstvo-rp-latnaa',
          'step',
        ],
      },
      {
        id: 33,
        title: 'Экспортеры',
        name: 'Control-Exporters',
        unload: [
          'cerkizovo-masla-g-elec',
          'cerkizovo-svinovodstvo-rp-latnaa',
          'step',
        ],
      },
      {
        id: 34,
        title: 'Черный список',
        name: 'Control-Blacklist',
        unload: [
          'cerkizovo-masla-g-elec',
          'cerkizovo-svinovodstvo-rp-latnaa',
          'step',
        ],
      },
      {
        id: 35,
        title: 'Транспорт терминала',
        name: 'Control-Whitelist',
        unload: [
          'cerkizovo-masla-g-elec',
          'cerkizovo-svinovodstvo-rp-latnaa',
          'step',
        ],
      },
    ],
  },
  {
    id: 15022022,
    title: 'Выезд',
    route: '/admin-supervisor',
    name: 'AdminSupervisor',
    icon: 'IconEntry',
  },
  {
    id: 6,
    title: 'Настройки',
    route: '/settings',
    name: 'Settings',
    icon: 'WrenchIcon',
  },
  {
    id: 4,
    title: 'Помощь',
    route: '/about',
    name: 'About',
    icon: 'IconHelp',
  },
  {
    id: 5,
    title: 'Профиль',
    route: '/profile',
    name: 'Profile-settings',
    icon: 'IconUserRegistration',
    children: [
      {
        id: 51,
        title: 'Мой профиль',
        name: 'Profile-settings',
      },
      {
        id: 52,
        title: 'Профили сотрудников',
        name: 'Profile-table',
      },
      {
        id: 53,
        title: 'Добавить нового сотрудника',
        name: '',
        dialog: PROFILE_NEW_USER,
      },
    ],
  },
]

export const mobileDriverMenu = [
  {
    id: 1,
    title: 'Получение',
    name: 'GettingSlots',
    path: '',
    icon: 'DriverPlus',
  },
  {
    id: 2,
    title: 'Мои таймслоты',
    name: 'Timeslots',
    path: 'timeslot',
    icon: 'TruckIcon',
  },
]

export const navigationRootList = [
  {
    id: 1,
    title: 'Квоты',
    name: 'Home',
  },
  {
    id: 3,
    title: 'Управление',
    name: 'Control-terminal',
  },
  {
    id: 4,
    title: 'Помощь',
    name: 'About',
  },
  {
    id: 5,
    title: 'Водитель. Получение',
    name: 'GettingSlots',
  },
  {
    id: 6,
    title: 'Водитель. Мои таймслоты',
    name: 'Timeslots',
  },
  {
    id: 7,
    title: 'Начальник смены',
    name: 'AdminSupervisor',
  },
  {
    id: 8,
    title: 'Паркмен',
    name: 'Parkman',
  },
  {
    id: 9,
    title: 'Менеджер',
    name: 'Manager',
  },
  {
    id: 10,
    title: 'Таблица статистики',
    name: 'Hourlimit',
  },
  {
    id: 2,
    title: 'Профиль',
    name: 'Profile-settings',
  },
  {
    id: 11,
    title: 'Настройки',
    name: 'Settings',
  },
]

export const navigationViewerList = [
  {
    id: 1,
    title: 'Квоты',
    name: 'Home',
  },
  {
    id: 2,
    title: 'Управление',
    name: 'Control-terminal',
  },
]

export const navigationExporterList = [
  {
    id: 1,
    title: 'Квоты',
    icon: 'IconQuotas',
    name: 'ExporterQuotas',
  },
  {
    id: 2,
    title: 'Таймслоты',
    icon: 'IconQuotas',
    name: 'ExporterManagement',
  },
  {
    id: 3,
    title: 'Поставщики',
    icon: 'IconControl',
    name: 'ExporterSuppliers',
  },
  {
    id: 4,
    title: 'Получение ТС',
    icon: 'TruckIcon',
    name: 'GettingSlots',
  },
  {
    id: 5,
    title: 'Мои ТС',
    icon: 'IconList',
    name: 'Timeslots',
  },
]
